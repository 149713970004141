<template>
  <div>
    <WebDesktopMenu
      v-if="desktop"
      :searchMenu="searchMenu"
      :authMenu="authMenu"
      :cartMenu="cartMenu"
    />
    <WebMobileMenu
      v-else
      :searchMenu="searchMenu"
      :authMenu="authMenu"
      :cartMenu="cartMenu"
    />
  </div>
</template>

<script>
import WebDesktopMenu from '@/components/layouts/navigation/typeMenu/WebDesktopMenu'
import WebMobileMenu from '@/components/layouts/navigation/typeMenu/WebMobileMenu'

export default {
  name: 'WebNav',

  components: { WebDesktopMenu, WebMobileMenu },

  data() {
    return {
      searchMenu: true,
      authMenu: false,
      cartMenu: true,
    }
  },

  computed: {
    desktop() {
      return window.innerWidth > 991
    },
  },
}
</script>
