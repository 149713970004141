import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

import auth from '@/store/auth'
import shop from '@/store/shop'

Vue.use(Vuex)

const APP_KEY = process.env.VUE_APP_KEY

const vuexLocal = new VuexPersist({
  key: APP_KEY,
  storage: window.localStorage,
})

export default new Vuex.Store({
  modules: { auth, shop },
  plugins: [vuexLocal.plugin],
})
