<template>
  <div class="web-footer">
    <section v-if="footerMenu" id="footer_menu">
      <b-container>
        <b-row>
          <b-col v-if="subscribe" sm="12" md="5">
            <SubscribeForm class="menu left-menu" />
          </b-col>
          <b-col sm="12" md="6" lg="5">
            <div class="menu">
              <div class="title mb-3">
                <p>Información de contacto</p>
              </div>
              <div class="my-2">
                <p class="mb-0">
                  <span>
                    <font-awesome-icon icon="phone" />
                    Tienda:&nbsp;
                  </span>
                  <a href="tel:+528125171362">812-517-1362</a>
                </p>
                <p class="mb-0">
                  <span>
                    <font-awesome-icon :icon="['fab', 'whatsapp']" />
                    Tienda whatsapp:&nbsp;
                  </span>
                  <a href="tel:+5218180177982">818-017-7982</a>
                </p>
                <p class="mb-0">
                  <span>
                    <font-awesome-icon icon="screwdriver-wrench" />
                    Taller de servicio:&nbsp;
                  </span>
                  <a href="tel:+5218183266892">818-326-6892</a>
                </p>
              </div>
              <div class="my-2">
                <p class="mb-0">
                  <span>
                    <font-awesome-icon icon="envelope" />
                    Email:&nbsp;
                  </span>
                  <a href="mailto:ventasacdparts-milwaukee@acdparts.mx">
                    ventasacdparts-milwaukee@acdparts.mx
                  </a>
                </p>
              </div>
              <!-- <p class="mb-0">Teléfonos: 812-517-1362 y 818-326-6892</p>
              <p class="mb-0">Whatsapp: 818-017-7982</p>
              <p class="mb-0">
                Email:
                <a href="ventasacdparts-milwaukee@acdparts.mx">
                  ventasacdparts-milwaukee@acdparts.mx
                </a>
              </p> -->
            </div>
          </b-col>
          <b-col sm="12" md="6" lg="5">
            <div class="menu">
              <div class="title">
                <p>Menu</p>
              </div>
              <div class="list">
                <router-link
                  v-for="(item, index) in menu"
                  :key="index"
                  :to="{ name: item.name }"
                >
                  <span>{{ item.meta.menuName }}</span>
                </router-link>
              </div>
            </div>
          </b-col>
          <!-- <b-col sm="12" md="4" lg="3">
            <div class="menu">
              <div class="title">
                <p>Siguenos</p>
              </div>
              <div class="list">
                <div class="social">
                  <div class="icons">
                    <font-awesome-icon :icon="['fab', 'instagram']" />
                    <font-awesome-icon :icon="['fab', 'facebook-f']" />
                  </div>
                </div>
              </div>
            </div>
          </b-col> -->
        </b-row>
        <b-row class="footer-bottom">
          <b-col md="12">
            <img class="img-fluid" src="@/assets/images/brand/logo.svg" />
          </b-col>
          <!-- <b-col md="6" class="d-flex justify-content-end">
            <div class="payments-methods">
              <font-awesome-icon :icon="['fab', 'cc-amex']" />
              <font-awesome-icon :icon="['fab', 'cc-mastercard']" />
              <font-awesome-icon :icon="['fab', 'cc-visa']" />
            </div>
          </b-col> -->
        </b-row>
      </b-container>
    </section>
    <section id="footer">
      <b-container>
        <b-row>
          <b-col sm="12">
            <div class="site text-center">
              <span>{{ appName }}</span>
              &nbsp;
              <span>&copy;</span>
              &nbsp;
              <span>{{ currentYear }}</span>
              &nbsp;
              <span>Todos los derechos reservados.</span>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import SubscribeForm from '@/components/shared/SubscribeForm'

export default {
  name: 'WebFooter',

  components: { SubscribeForm },

  data() {
    return {
      footerMenu: true,
      subscribe: false,
    }
  },

  computed: {
    menu() {
      return this.$router.options.routes
        .filter((route) => route.meta.menu == 'footerMenu' && route.meta.listed)
        .slice(0, 5)
    },
    appName() {
      return process.env.VUE_APP_NAME
    },
    currentYear() {
      return new Date().getFullYear()
    },
  },
}
</script>
