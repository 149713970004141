<template>
  <div class="whatsapp">
    <div v-if="isButton" class="whatsapp-button">
      <a
        :href="shareWhatsappCurrentPage()"
        class="whatsapp-button-link"
        target="_blank"
      >
        <font-awesome-icon :icon="['fab', 'whatsapp']" />
        <span>{{ buttonText }}</span>
      </a>
    </div>
    <div v-else class="whatsapp-chat">
      <a :href="shareWhatsapp()" class="whatsapp-share" target="_blank">
        <font-awesome-icon :icon="['fab', 'whatsapp']" />
        <span>COMPARTIR</span>
      </a>
      <a :href="messageWhatsapp()" class="whatsapp-message" target="_blank">
        <font-awesome-icon :icon="['fab', 'whatsapp']" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WhatsappChat',

  props: {
    isButton: { type: Boolean },
    buttonText: { type: String },
  },

  methods: {
    shareWhatsappCurrentPage() {
      const whatsapp = 'https://api.whatsapp.com/send?text='
      const currentUrl = window.location.href
      const appName = process.env.VUE_APP_NAME
      const text = `${appName} | ${currentUrl}`
      const url = whatsapp + text
      return url
    },
    shareWhatsapp() {
      const whatsapp = 'https://api.whatsapp.com/send?text='
      const appUrl = process.env.VUE_APP_URL
      const appName = process.env.VUE_APP_NAME
      const text = `${appName} | ${appUrl}`
      const url = whatsapp + text
      return url
    },
    messageWhatsapp() {
      const whatsapp =
        'https://api.whatsapp.com/send?phone=+5218180177982&text='
      const text = '¡Hola!'
      const url = whatsapp + text
      return url
    },
  },
}
</script>
