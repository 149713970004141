const shop = {
  namespaced: true,
  state: {
    cart: [],
    showCart: false,
    guest_user: {},
    is_guest: false,
  },
  mutations: {
    ADD_ITEM(state, item) {
      const product = state.cart.find((p) => p.id == item.id)
      if (product == null) {
        state.cart.push(item)
      } else {
        let sum = item.qty + product.qty
        product.qty = product.qty >= 10 ? 10 : sum >= 10 ? 10 : sum
      }
    },
    UPDATE_QTY(state, item) {
      const product = state.cart.find((p) => p.id == item.id)
      product.qty = product.qty >= 10 ? 10 : item.qty // limited by 10 units of every product
    },
    REMOVE_ITEM(state, index) {
      state.cart.splice(index, 1)
    },
    SHOW_CART(state, boolean) {
      console.log({ boolean })
      state.showCart = boolean
    },
    IS_GUEST(state, bool) {
      state.is_guest = bool
    },
    SET_GUEST(state, user) {
      state.guest_user = user
    },
    CLEAR(state) {
      state.cart = []
      state.showCart = false
    },
    CLEAR_GUEST(state) {
      state.guest_user = {}
      state.is_guest = false
    },
  },
  actions: {
    addItem({ commit }, item) {
      commit('ADD_ITEM', item)
    },
    removeItem({ commit }, index) {
      commit('REMOVE_ITEM', index)
    },
    setGuest({ commit }, user) {
      commit('IS_GUEST', true)
      commit('SET_GUEST', user)
    },
  },
  getters: {
    showCartModal(state) {
      return state.showCart
    },
    cartProducts(state) {
      return state.cart
    },
    itemsInCart(state) {
      let cart = state.cart
      return cart.reduce((accum, item) => accum + item.qty, 0)
    },
    totalCart(state) {
      let cart = state.cart
      return cart.reduce((accum, item) => accum + item.price * item.qty, 0)
    },
    isGuest(state) {
      return state.is_guest
    },
    guestUser(state) {
      return state.guest_user
    },
  },
}
export default shop
