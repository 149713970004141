const APP_KEY = process.env.VUE_APP_KEY
const auth = {
  namespaced: true,
  state: {
    user: {
      name: '',
      lastName: '',
      email: '',
      accessToken: '',
    },
    admin: false,
    logged: false,
  },
  mutations: {
    LOGIN(state, user) {
      state.user.name = user.name
      state.user.lastName = user.lastName
      state.user.email = user.email
    },
    SET_TOKEN(state, token) {
      state.user.accessToken = token
    },
    IS_ADMIN(state, admin) {
      state.admin = admin
    },
    LOGGED(state, boolean) {
      state.logged = boolean
    },
    LOGOUT() {
      localStorage.removeItem(APP_KEY)
    },
    UPDATE_INFO(state, user) {
      state.user.name = user.name
      state.user.lastName = user.lastName
      state.user.email = user.email
    },
  },
  actions: {
    login({ commit }, payload) {
      commit('LOGIN', payload.user)
      commit('SET_TOKEN', payload.token)
      commit('IS_ADMIN', payload.admin)
      commit('LOGGED', true)
    },
    logOut({ commit }) {
      commit('LOGOUT')
    },
    updateUserInfo({ commit }, payload) {
      commit('UPDATE_INFO', payload)
    },
  },
  getters: {
    user(state) {
      return state.user
    },
    isAdmin(state) {
      return state.admin
    },
    logged() {
      // const project_storage = JSON.parse(localStorage.getItem(APP_KEY))
      // return state.logged && project_storage != null
      return false
    },
    token(state) {
      return state.user.accessToken
    },
  },
}
export default auth
