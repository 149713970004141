<template>
  <b-navbar toggleable="lg" variant="faded" type="light" class="nav-desktop">
    <NavBrand class="d-flex align-items-center" />
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <WebMenu menuType="webMenu" class="align-items-center" />
      <div class="d-flex">
        <SearchMenu v-if="searchMenu" class="align-items-center" />
        <WebMenu
          v-if="authMenu && !logged"
          menuType="authMenu"
          class="align-items-center"
        />
        <UserMenu v-if="authMenu && logged" class="align-items-center" />
        <CartButton v-if="cartMenu" class="align-items-center" />
      </div>
    </b-collapse>
  </b-navbar>
</template>

<script>
import NavBrand from '@/components/layouts/navigation/menu/NavBrand'
import WebMenu from '@/components/layouts/navigation/menu/WebMenu'
import SearchMenu from '@/components/layouts/navigation/menu/SearchMenu'
import UserMenu from '@/components/layouts/navigation/menu/UserMenu'
import CartButton from '@/components/shop/CartButton'
import { mapGetters } from 'vuex'

export default {
  name: 'WebDesktopMenu',

  components: {
    NavBrand,
    WebMenu,
    SearchMenu,
    UserMenu,
    CartButton,
  },

  props: {
    searchMenu: { type: Boolean },
    authMenu: { type: Boolean },
    cartMenu: { type: Boolean },
  },

  computed: {
    ...mapGetters('auth', ['logged']),
  },
}
</script>
